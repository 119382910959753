<template>
  <section class="main-section cases-section">
    <waterfall v-if="cases.length" class="cases-layout" :col="col" :autoResize="true" :moveTransitionDuration="0.4" :fillBox="true" :list="cases" ref="waterfall" imgKey="img">
        <div class="waterfall-item bounceIn cases-card" slot-scope="item">
          <a @click="previewImgs(item.data.key)">
            <div class="cases-img-div">
              <img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/case/' + item.data.key + '.png'" style="width: 100%" class="waterfall-img cases-img">
            </div>
            <h3 class="cases-title">{{item.data.name}}</h3>
          </a>
          <div v-if="item.data.city" class="cases-city"><span class="cases-city-icon"></span>{{ item.data.city }}</div>
        </div>
    </waterfall>
    <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
  </section>
</template>
<script>
import waterfall from 'vue-waterfall-rapid'
import VueGallerySlideshow from 'vue-gallery-slideshow'
export default {
  name: 'vue-waterfall-rapid',
  components: {
    waterfall,
    VueGallerySlideshow
  },
  data: () => ({
    catId: 18,
    cases: [
      {
        key: 'yhs',
        name: '御华山',
        city: '上海'
      },
      {
        key: 'tnml',
        name: '桐南美麓',
        city: '上海'
      },
      {
        key: 'gbjh',
        name: '古北竞衡88大厦',
        city: '上海'
      },
      {
        key: 'nxsxslhs',
        name: '南翔三湘森林海尚',
        city: '上海'
      },
      {
        key: 'zjjjjjd',
        name: '朱家角锦江酒店',
        city: '上海'
      },
      {
        key: 'dszx',
        name: '鼎森中心',
        city: '大连'
      },
      {
        key: 'lhtj',
        name: '龙湖天钜',
        city: '上海'
      },
      {
        key: 'xgll',
        name: '香格里拉',
        city: '苏州'
      },
      {
        key: 'sxtdds',
        name: '水秀天地大厦',
        city: '苏州'
      },
      {
        key: 'sxhsmd',
        name: '三湘海尚名邸',
        city: '上海'
      },
      {
        key: 'sxqxfd',
        name: '三湘七星府邸',
        city: '上海'
      },
      {
        key: 'yyamjd',
        name: '养云安缦酒店',
        city: '上海'
      },
      {
        key: 'wlc',
        name: '万立城',
        city: '上海'
      },
      {
        key: 'bgyly',
        name: '碧桂园领誉',
        city: '扬州'
      },
      {
        key: 'rmjhy',
        name: '荣民玖号院',
        city: '西安'
      },
      {
        key: 'sxyxmd',
        name: '三湘印象名邸',
        city: '上海'
      },
      {
        key: 'lcjnl',
        name: '绿城江南里',
        city: '杭州'
      }
    ],
    page: 1,
    col: 2,
    images: [],
    index: null
  }),
  created () {
    this.$store.commit('updateActiveNav', 3)
    // this.getCases(this.page, this.catId)
  },
  mounted () {
    /*
    this.$refs.waterfall.onRender = (res) => {
      console.log('渲染完毕', res)
    }
    */
  },
  watch: {
    page: {
      handler: function (val, oldVal) {
        this.getCases(val, this.catId)
      },
      deep: true
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    getCases (page, cid) {
      this.https.get('cases', { page: page, cid: cid, pageSize: 4 }).then(response => {
        if (response.code === 0) {
          let data = response.data.data
          for (let i = 0; i < data.length; i++) {
            data[i]['city'] = null
            let titleArr = data[i].title.split(' • ')
            if (titleArr.length === 2) {
              data[i]['city'] = titleArr[0]
              data[i]['title'] = titleArr[1]
            }
          }
          this.cases = data
        }
      })
    },
    previewImgs (img) {
      img = 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/case/' + img + '.png'
      this.images = [img]
      this.index = 0
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
